import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby-theme-material-ui";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Seo } from "../components/Seo";

const ErrorPage = () => (
  <>
    <Seo pageTitle={"Нещо се случи"} />
    <Container>
      <Box
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StaticImage
          src="../images/undraw_Page_not_found.png"
          alt="A dinosaur"
          loading="eager"
          layout="fullWidth"
          objectPosition="50% 100%"
          placeholder="blurred"
          style={{ width: "100%", maxWidth: 500 }}
        />
        <Typography variant="h2" textAlign="center" sx={{ mb: 5 }}>
          Нещо се случи
        </Typography>
        <Link to="/">Обратно към начална страница</Link>
      </Box>
    </Container>
  </>
);

export default ErrorPage;
